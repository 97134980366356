import React, { useState, useEffect } from "react";
import ReactHelmet from "../components/core/Helmet";
import Button from "../components/core/button/Button";
import { useHistory } from "react-router";
import { routes } from "../routes";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "../components/core/typography/Typography";
import Space from "../components/core/Space";
import Select from "../components/core/input/Select";
import Input from "../components/core/input/Input";
import Click from "../components/core/link/Click";
import Vector from "../static/media/img/LoginCover2.png";
import { makeStyles } from "@material-ui/core/styles";
import IconClick from "../components/core/icons/IconClick";
import FlexContainer from "../components/core/containers/flex/FlexContainer";
import sunCloudyLine from "@iconify/icons-ri/sun-cloudy-line";
import { isDarkTheme } from "../utils/appSetting";
import moonCloudyLine from "@iconify/icons-ri/moon-cloudy-line";
import { toggleDarkMode } from "../redux/actions/appSetting";
import FlexItem from "../components/core/containers/flex/FlexItem";
import Checkbox from "../components/core/input/Checkbox";
import { setContext } from "../redux/actions/context";
import MessageDialog from "../components/core/dialogs/MessageDialog";
import LoginFooter from "../layout/LoginFooter";
import DataPrivacyDialog from "../components/Dialogs/DataPrivacyDialog";
import { campuses } from "../constants/campuses";
import { useForm } from "react-hook-form";
import { toggle, toggleSet } from "../redux/actions/toggle";
import LoginHeader from "../layout/LoginHeader";
import { Container } from "@material-ui/core";
import SendUsMessage from "../components/SendUsMessage";
import { login } from "../redux/actions/auth";
import globalStyle from "../static/styles";

export default function Login() {
  const styles = style();
  const history = useHistory();
  const isDark = isDarkTheme();
  const globalStyles = globalStyle();

  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    setValue,
    watch,
  } = useForm();

  const {
    loginFormError,
    loginFormErrorDescription,
    showPassword,
    isLoggingIn,
    enteredPathname,
  } = useSelector((state) => ({
    enteredPathname: state.CONTEXT.enteredPathname,
    loginFormError: state.CONTEXT.loginFormError,
    loginFormErrorDescription: state.CONTEXT.loginFormErrorDescription,
    showPassword: state.TOGGLE.loginShowPassword,
    isLoggingIn: state.TOGGLE.loginIsSubmitting,
  }));

  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const onLogin = (data) => {
    login(data.idNumber, data.password, data.campus, () => {
      history.replace(enteredPathname ? enteredPathname : routes.profile);
    });
  };

  const toggleShowPassword = () => {
    toggle("loginShowPassword");
  };

  const campusOnSelect = (id) => {
    clearErrors("campus");
    setValue("campus", id);
  };

  const onEnter = (event) => {
    if (event.key === "Enter") handleSubmit(onLogin)();
  };

  const handleCapsLock = (event) => {
    if (event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  const closeErrorDialog = () => setContext("loginFormError", null);
  const forgotPassword = () => history.push(routes.forgotPassword);

  useEffect(() => {
    register("campus", { required: "Campus is required" });
  });

  return (
    <div className={[styles.root]}>
      <ReactHelmet title={"Login"} />
      <LoginHeader />
      <Container fixed>
        <Grid container className={styles.body}>
          <Grid item md={6}>
            <Space vertical space={12} />
            <Typography variant={"h3"} weight={"medium"}>
              Hello there!
            </Typography>
            <Space vertical space={1} />
            <Typography variant={"h4"} weight={"light"}>
              Please login to get started.
            </Typography>
            <Space vertical space={4} />
            <Select
              onSelect={campusOnSelect}
              value={watch("campus")}
              items={campuses}
              placeholder={"Campus"}
              size={"medium"}
              width={300}
              error={errors.campus?.message}
            />
            <Space vertical space={2} />
            <form>
              <Input
                inputRef={register({ required: "ID Number is required" })}
                name={"idNumber"}
                placeholder={"ID Number"}
                size={"medium"}
                width={300}
                autocomplete="none"
                // autocomplete="off"
                // autocomplete="new-password"
                error={errors.idNumber?.message}
                onKeyDown={onEnter}
              />
            </form>
            <Space vertical space={2} />
            {isCapsLockOn && (
              <Typography  weight={"semibold"}>
              Caps Lock is On!
            </Typography>
            )}
            <form>
              <Input
                inputRef={register({ required: "Password is required" })}
                name={"password"}
                type={showPassword ? "text" : "password"}
                error={errors.password?.message}
                placeholder={"Password"}
                size={"medium"}
                width={300}
                // onKeyDown={onEnter}
                onKeyDown={(e) => {
                  handleCapsLock(e);
                  onEnter(e);
                }}
              />
            </form>
            <Space vertical space={3} />

            <div className={globalStyles.textNoSelect}>
              <FlexContainer alignItems={"center"} onClick={toggleShowPassword}>
                <Checkbox checked={showPassword} color={"primary"} />
                <Space space={2} />
                <Typography>Show Password</Typography>
              </FlexContainer>
            </div>

            <Space vertical space={6} />
            <Button
              variant={"primary"}
              size={"large"}
              // fullwidth
              onClick={handleSubmit(onLogin)}
              isLoading={isLoggingIn}
            >
              Log me in
            </Button>

            <Space vertical space={8} />

            <Click onClick={forgotPassword}>
              <Typography>Forgot password?</Typography>
            </Click>

            <LoginFooter />
          </Grid>
          <Grid item md={6}>
            <FlexContainer direction={"column"} alignItems={"flex-end"}>
              <div className={styles.rightActions}>
                <FlexItem>
                  <FlexContainer alignItems={"center"} onClick={toggleDarkMode}>
                    <IconClick
                      icon={isDark ? moonCloudyLine : sunCloudyLine}
                      disableHover
                    />
                    <Space space={1} />
                    <Typography weight={"medium"}>
                      {isDark ? "Dark" : "Light"} Mode
                    </Typography>
                  </FlexContainer>
                </FlexItem>
                <Space vertical space={3} />
                <Typography align={"right"}>Having trouble?</Typography>
                <Click onClick={() => toggleSet("loginOpenMessage", true)}>
                  <Typography weight={"bold"} align={"right"}>
                    Send us a message
                  </Typography>
                </Click>
              </div>
              <FlexItem>
                <img src={Vector} alt={"Vector"} className={styles.vector} />
              </FlexItem>
            </FlexContainer>
          </Grid>
        </Grid>
        <Space vertical space={5} />
      </Container>

      <MessageDialog
        open={loginFormError}
        variant={"error"}
        title={loginFormError}
        onConfirmLabel={"Okay"}
        singleAction
        onClose={closeErrorDialog}
        description={loginFormErrorDescription}
        onConfirm={closeErrorDialog}
      />

      <DataPrivacyDialog />
      <SendUsMessage />
    </div>
  );
}

const style = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
  },
  body: {
    padding: "0 52px",
  },
  vector: {
    width: 600,
    marginTop: -30,
    marginRight: 80,
    [theme.breakpoints.down("md")]: {
      width: 560,
      marginRight: -80,
      marginTop: -80,
    },
  },
  rightActions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(18),
    },
  },
}));
